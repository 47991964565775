.btn-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.btn-basic {
  /* flex: 1 1 auto; */
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 31px;
  font-size: 18px;
  font-weight: 400;
  margin: auto;
}

.btn-basic:hover {
  border: 1px solid white;
  color: white;
}

.btn-gradient {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  color: white;

  background: -moz-linear-gradient(60deg, #3d7ffb, #eb70f1);
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  background: linear-gradient(60deg, #3d7ffb, #eb70f1);

  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  background-clip: text;
}

.btn-gradient:hover {
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.btn-gradient .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #5142fc;
}

.btn-gradient .icon:hover {
  color: #ffffff;
}
