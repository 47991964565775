.card-cont{
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 2px solid #6C63FF;

    background-color: #fff;
    transition:0.5s;
    
    animation: ease-in-out;
    
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.card-cont:hover{
    transform: scale(1.02);
    box-shadow: 0px 0px 15px 0px;
}

.card-img-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    min-height: 100px;
}

.card-text-wrap{
    background-color: #101522;
    padding: 20px
}

.card-app-img{
    overflow: hidden;
    height: 250px;
    width: 90%;
    background-color: transparent;
}

.app-card-decription{
    color: #fff;
    font-size:17px;
    text-align: center;
}


.card-app-btn-wrap{
    display: flex;
    justify-content: center;
    padding: 20px;
    background: #101522;
}