.btn-container {
  display: flex;
  padding-left: 0.5em;
}

.btn-social {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  font-size: 27px;
  width: 55px;
  border-radius: 15px;
  background-color: #343444;
  color: #ffffff;
}

.btn-social:hover {
  background-color: #5142fc;
  color: #ffffff;
  transform: scale(1.1);
}
