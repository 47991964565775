.item-container {
  display: flex;
  justify-content: center;
  background-color: #09080d;
}

.item-row {
  margin-right: 0 !important;
}

.item-preview {
  display: flex;
  justify-content: center;
}

.item-preview .frame {
  position: relative;
  z-index: 1;
  width: 180%;
}

.item-preview .nft {
  position: absolute;
  left: 12.6%;
  top: 20vw;
  width: 23.9%;
}

.item-content {
  display: flex;
  justify-content: flex-start;
}

.item-content .item-description {
  padding: 12vw 0vw;
}

.item-description h2 {
  font-size: 35px;
}

.item-wrapper {
  width: 100%;
  text-align: left;
}

.user-box {
  display: inline-block;
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 250px;
  height: 54px;
}

.user-content {
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 10px;
  margin-top: 6px;
}

.user-content img {
  border-radius: 100%;
  width: 37px;
  margin-right: 10px;
}

.user-desc {
  margin-top: 8px;
  font-size: 11px;
  color: #d2d2d2;
  text-decoration: none;
}

.user-desc h5 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}

.user-desc h6 {
  font-size: 11px;
  letter-spacing: 0.2px;
  font-weight: 300;
  line-height: 0.5;
}

.item-box {
  display: inline-block;
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 250px;
}

.item-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.item-left {
  justify-content: left;
  color: #ffffff;
  font-weight: 100;
}

.item-right {
  text-align: right;
  font-weight: 350;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
}

.item-story {
  color: #808080;
  font-size: 15px;
  font-weight: 100;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 510px;
}

.item-button {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  cursor: pointer;
}

/* Reverse comportement of {@link GradientButton} component */
.item-button .btn-basic {
  position: relative;
  right: 85px;
  height: 48px;
  width: 290px;
  font-size: 17px !important;
  letter-spacing: 0.5px;
  font-weight: 300;
  border-radius: 28px !important;
}

.item-button .btn-gradient {
  justify-content: space-around !important;
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.item-button .btn-gradient:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background: transparent;
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.item-button .item-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  z-index: 3;
  width: 300px;
  border: 2px solid #09080d;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  border-radius: 28px;
  background-color: rgba(52, 52, 68);
  text-align: center;
}

.item-button .input-value {
  background-color: transparent;
  color: #eeeeee;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #6969696c 1px;
  padding: 0px 50px;
}

input {
  width: 180px;
  font-size: 17px !important;
  font-weight: 100;
}

.item-input .icon {
  position: relative;
  right: 25px;
  color: #ffffff;
}
