/******************
 *      HEADING    *
******************/

.home-container {
  display: flex;
  height: 1080px;

  background-image: url(../../ImagesVideo/Background.jpg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right top;

  background-color: #09080d;
}

@media only screen and (max-width: 1920px) {
  .home-container {
    background-image: url(../../ImagesVideo/Background-medium-g.jpg);
  }
}

@media only screen and (max-width: 1440px) {
  .home-container {
    background-image: url(../../ImagesVideo/Background-medium.jpg);
  }
}

@media only screen and (max-width: 1024px) {
  .home-container {
    background-image: url(../../ImagesVideo/Background-small.jpg);
  }
}

.home-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  width: auto;
}

.home-title {
  position: relative;
  top: 10%;
  right: 15%;
}

.home-title h2,
span {
  position: relative;
  text-transform: uppercase;
  font-size: 38px !important;
}

.title-2 {
  margin-top: -25px;
  right: 10%;
}

.title-3 {
  margin-top: -25px;
  left: 10%;
}

.home-side {
  width: 450px;
  height: fit-content;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  left: 260px;
  top: 100px;
  position: relative;
}

.heading-right {
}

.heading-right a {
  font-family: 'Montserrat' !important;
  font-size: 19px !important;
}

/* Reverse comportement of {@link GradientButton} component */
.heading-right .btn-gradient {
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.heading-right .btn-gradient:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background: transparent;
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Arrow */

.arrow {
  position: relative;
  margin: 0 auto;
  width: 100px;
}

.arrow .curve {
  border: 2px solid #943ff8;
  border-color: transparent transparent transparent #943ff8;
  height: 360px;
  width: 300px;
  border-radius: 230px 0 0 150px;
}

.arrow .point {
  position: absolute;
  left: 40px;
  top: 315px;
}

.arrow .point:before,
.arrow .point:after {
  border: 1px solid #943ff8;
  background-color: #943ff8;
  height: 25px;
  content: '';
  position: absolute;
}

.arrow .point:before {
  top: -11px;
  left: -11px;
  transform: rotate(-74deg);
  -webkit-transform: rotate(-74deg);
  -moz-transform: rotate(-74deg);
  -ms-transform: rotate(-74deg);
}

.arrow .point:after {
  top: -20px;
  left: 5px;
  transform: rotate(12deg);
  -webkit-transform: rotate(12deg);
  -moz-transform: rotate(12deg);
  -ms-transform: rotate(12deg);
}

/* Responsiveness of heading content */
@media (min-width: 1700px) {
  .home-title {
    position: relative;
    right: 0;
  }

  .home-title h2,
  span {
    font-size: 45px !important;
  }

  .home-side {
    left: 520px !important;
  }
}

@media screen and (min-width: 2200px) {
  .home-side {
    left: 900px !important;
  }
}

@media screen and (min-width: 1600px) {
  .home-side {
    left: 500px !important;
  }
}

@media screen and (min-width: 1900px) {
  .home-side {
    left: 719px !important;
  }
}

@media screen and (min-width: 2400px) {
  .home-side {
    left: 1080px !important;
  }
}
/* END OF HEADING */

/******************
 *   MAIN CONTENT  *
******************/
.home-content {
  height: auto;
  width: 100%;
  background-color: #09080d;
}

.home-cont {
  display: flex;
  height: 50vh;
  width: 100%;
  justify-content: center;
  padding: 10px;
}

.home-wrap {
  display: flex;
}

.home-left {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.home-title-white {
  text-align: center;
  color: #fff;
  font-weight: 250;
}

.home-title-gradient {
  text-align: center;
  left: 40px;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 250;
}

.home-moon {
  position: relative;
  height: 400px;
  overflow: hidden;
  top: 30%;
  right: 7%;
  mix-blend-mode: screen;
  transform: translate(0, 25px);
  width: 400px;
}

.home-right {
  display: grid;
  color: #fff;
  background-repeat: no-repeat;
  background-image: url('../../ImagesVideo/asteroid.svg');
  height: 100%;
  background-size: contain;
  width: 60vh;
}

.home-asteroid {
  height: 50vh;
  background-size: contain;
}

.home-community {
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
}

.home-app-select-cont {
  color: #fff;
  height: auto;
  width: 100%;
  padding-bottom: 40px;
  background-color: #09080d;
}

.home-app-select-wrap {
  display: grid;
  grid-template-columns: repeat(2, minmax(14rem, 16rem));
  grid-gap: 40px;
  position: float;
  background-color: #09080d;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
}


.testnet {
  grid-template-columns: repeat(3, minmax(14rem, 16rem));
}
