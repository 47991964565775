@import url(https://fonts.googleapis.com/css?family=Poppins:400,600|Montserrat:700,500);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Sofia Pro', sans-serif;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
}

.btn-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.btn-basic {
  /* flex: 1 1 auto; */
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 31px;
  font-size: 18px;
  font-weight: 400;
  margin: auto;
}

.btn-basic:hover {
  border: 1px solid white;
  color: white;
}

.btn-gradient {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  color: white;
  background: linear-gradient(60deg, #3d7ffb, #eb70f1);

  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  background-clip: text;
}

.btn-gradient:hover {
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.btn-gradient .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #5142fc;
}

.btn-gradient .icon:hover {
  color: #ffffff;
}

.header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 900;
  background: #09080d;
}

.navigation {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 120;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
}

@media (max-width: 643px) {
  #nav {
    display: none;
  }
}

.title {
  color: #ffffff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
}

.logo {
  font-size: 1.9rem !important;
}

.title:hover {
  color: #ffffff;
}

.menu {
  margin-right: 30px;
  margin-left: auto;
  display: flex;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.menu ul {
  align-items: flex-end;
  display: flex;
  margin: 0 auto;

  color: #ffffff;
  cursor: pointer;
}

.menu ul li {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-weight: 100;
}

.menu ul li a:hover {
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu ul li a {
  color: #ffffff;
}

.menu ul li a:visited {
  color: #ffffff;
  font-size: 40px;
}

.menu ul li a:link {
  display: flex;
  color: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  font-size: 18px;

  justify-content: center;
  text-decoration: none;
}

.user-profile {
  display: flex;
  align-items: center;
}

.profile-menu {
  background: #181820;
  color: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 80px;
  right: 105px;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  transition: opacity 0.4s ease, visibility 0.4s, -webkit-transform 0.4s ease;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s, -webkit-transform 0.4s ease;
}

.profile-menu.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.profile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-menu li a {
  text-decoration: none;
  font-size: 17px;
  color: #ffffff;
  padding: 15px 0;
  display: block;
}

.profile-dropdown {
  padding: 15px 20px;
}

.profile-dropdown .profile-info {
  margin-top: 10px;
  font-size: 13px;
}

.profile-dropdown .profile-info p {
  line-height: 10px;
  font-weight: 100;
}

.profile-dropdown .profile-info span {
  font-size: 17px !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #af75f4;
}

.profile-dropdown .profile-info .profile-wallet {
  font-size: 17px !important;
  opacity: 80%;
  font-weight: 400;
  text-transform: none;
  text-transform: initial;
  color: #ffffff;
}

hr.line {
  height: 1px !important;
  margin-bottom: 10px;
}

.btn-container {
  display: flex;
  padding-left: 0.5em;
}

.btn-social {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  font-size: 27px;
  width: 55px;
  border-radius: 15px;
  background-color: #343444;
  color: #ffffff;
}

.btn-social:hover {
  background-color: #5142fc;
  color: #ffffff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.footer-container {
  position: relative;
  cursor: pointer;
  background-color: #000000;
}

@media (max-width: 643px) {
  .footer-links-wrap,
  .socialmedia {
    flex-direction: column;
  }

  .footer-links-items {
    margin: 0;
    padding: 10px;
    width: 100%;
  }

  .copyright-wrap {
    margin-left: unset !important;
  }

  .socialmedia {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}

.footer-wrap {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-links-cont {
  display: flex;
  width: 100%;
}

.footer-links-wrap {
  display: flex;
  max-width: 1100px;
  margin: auto;
}

.footer-links-items {
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  width: auto;
  box-sizing: border-box;
  color: #ffffff;
}

.footer-links-logo {
  font-size: 35px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: #ffffff;
}

.footer-links-title {
  font-size: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: #ffffff;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  padding-bottom: 0.5rem;
  font-size: 14px;
}

.footer-link:hover {
  background: linear-gradient(60deg, #3d7ffb, #eb70f1);

  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  transition: 0.3s ease-out;
}

.socialmedia {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  display: flex;
  width: 100%;
}

.socialmedia-wrap {
  margin-right: auto;
  left: 10%;
}

.copyright-wrap {
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: auto;
}

.social-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  padding-right: 10px;
}

.website-rights {
  color: #ffffff;
  font-size: 12px;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  width: 100%;

  @media screen and (max-width: 820px) {
    align-items: center;
  }
}

.footer-description {
  display: flex;
  max-width: 500px;
  font-weight: 100;
  font-size: 14px;
  padding-bottom: 1rem;
}

.footer-text-wrap {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  color: #ffffff;
}

.footer-logo-cont {
  background: transparent;
}

.footer-logo-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  max-height: 3rem;
  max-width: 3rem;

  --o-object-fit: cover;
  object-fit: cover;
  background: transparent;
}

.card-cont{
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 2px solid #6C63FF;

    background-color: #fff;
    transition:0.5s;
    
    -webkit-animation: ease-in-out;
    
            animation: ease-in-out;
    
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.card-cont:hover{
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    box-shadow: 0px 0px 15px 0px;
}

.card-img-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    min-height: 100px;
}

.card-text-wrap{
    background-color: #101522;
    padding: 20px
}

.card-app-img{
    overflow: hidden;
    height: 250px;
    width: 90%;
    background-color: transparent;
}

.app-card-decription{
    color: #fff;
    font-size:17px;
    text-align: center;
}


.card-app-btn-wrap{
    display: flex;
    justify-content: center;
    padding: 20px;
    background: #101522;
}
/******************
 *      HEADING    *
******************/

.home-container {
  display: flex;
  height: 1080px;

  background-image: url(/static/media/Background.31ad1e51.jpg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right top;

  background-color: #09080d;
}

@media only screen and (max-width: 1920px) {
  .home-container {
    background-image: url(/static/media/Background-medium-g.d63ba0be.jpg);
  }
}

@media only screen and (max-width: 1440px) {
  .home-container {
    background-image: url(/static/media/Background-medium.8a07c47b.jpg);
  }
}

@media only screen and (max-width: 1024px) {
  .home-container {
    background-image: url(/static/media/Background-small.6caf554d.jpg);
  }
}

.home-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: space-between;
  width: auto;
}

.home-title {
  position: relative;
  top: 10%;
  right: 15%;
}

.home-title h2,
span {
  position: relative;
  text-transform: uppercase;
  font-size: 38px !important;
}

.title-2 {
  margin-top: -25px;
  right: 10%;
}

.title-3 {
  margin-top: -25px;
  left: 10%;
}

.home-side {
  width: 450px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  left: 260px;
  top: 100px;
  position: relative;
}

.heading-right {
}

.heading-right a {
  font-family: 'Montserrat' !important;
  font-size: 19px !important;
}

/* Reverse comportement of {@link GradientButton} component */
.heading-right .btn-gradient {
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.heading-right .btn-gradient:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background: transparent;
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Arrow */

.arrow {
  position: relative;
  margin: 0 auto;
  width: 100px;
}

.arrow .curve {
  border: 2px solid #943ff8;
  border-color: transparent transparent transparent #943ff8;
  height: 360px;
  width: 300px;
  border-radius: 230px 0 0 150px;
}

.arrow .point {
  position: absolute;
  left: 40px;
  top: 315px;
}

.arrow .point:before,
.arrow .point:after {
  border: 1px solid #943ff8;
  background-color: #943ff8;
  height: 25px;
  content: '';
  position: absolute;
}

.arrow .point:before {
  top: -11px;
  left: -11px;
  transform: rotate(-74deg);
  -webkit-transform: rotate(-74deg);
  -moz-transform: rotate(-74deg);
  -ms-transform: rotate(-74deg);
}

.arrow .point:after {
  top: -20px;
  left: 5px;
  transform: rotate(12deg);
  -webkit-transform: rotate(12deg);
  -moz-transform: rotate(12deg);
  -ms-transform: rotate(12deg);
}

/* Responsiveness of heading content */
@media (min-width: 1700px) {
  .home-title {
    position: relative;
    right: 0;
  }

  .home-title h2,
  span {
    font-size: 45px !important;
  }

  .home-side {
    left: 520px !important;
  }
}

@media screen and (min-width: 2200px) {
  .home-side {
    left: 900px !important;
  }
}

@media screen and (min-width: 1600px) {
  .home-side {
    left: 500px !important;
  }
}

@media screen and (min-width: 1900px) {
  .home-side {
    left: 719px !important;
  }
}

@media screen and (min-width: 2400px) {
  .home-side {
    left: 1080px !important;
  }
}
/* END OF HEADING */

/******************
 *   MAIN CONTENT  *
******************/
.home-content {
  height: auto;
  width: 100%;
  background-color: #09080d;
}

.home-cont {
  display: flex;
  height: 50vh;
  width: 100%;
  justify-content: center;
  padding: 10px;
}

.home-wrap {
  display: flex;
}

.home-left {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.home-title-white {
  text-align: center;
  color: #fff;
  font-weight: 250;
}

.home-title-gradient {
  text-align: center;
  left: 40px;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 250;
}

.home-moon {
  position: relative;
  height: 400px;
  overflow: hidden;
  top: 30%;
  right: 7%;
  mix-blend-mode: screen;
  -webkit-transform: translate(0, 25px);
          transform: translate(0, 25px);
  width: 400px;
}

.home-right {
  display: grid;
  color: #fff;
  background-repeat: no-repeat;
  background-image: url(/static/media/asteroid.4780b46f.svg);
  height: 100%;
  background-size: contain;
  width: 60vh;
}

.home-asteroid {
  height: 50vh;
  background-size: contain;
}

.home-community {
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
}

.home-app-select-cont {
  color: #fff;
  height: auto;
  width: 100%;
  padding-bottom: 40px;
  background-color: #09080d;
}

.home-app-select-wrap {
  display: grid;
  grid-template-columns: repeat(2, minmax(14rem, 16rem));
  grid-gap: 40px;
  position: float;
  background-color: #09080d;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
}


.testnet {
  grid-template-columns: repeat(3, minmax(14rem, 16rem));
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.popup-inner {
    position: relative;
    text-align: center;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border-radius: 20px;

}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}

.popup-inner .img-logo {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 4rem;
    width: 4rem;

}
.cido-container {
  display: flex;
  background-color: #09080d;
  justify-content: center;
}

.row-custom {
  padding: 96px 100px 128px 100px;
  background-color: #09080d;
  justify-content: center;
  align-items: center;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.cido-cont-one {
  height: 50vh;
  width: 100%;
  background-color: #09080d;
  justify-content: center;
}

.cido-wrap-one {
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.title-white {
  text-align: center;
  color: #fff;
}

.title-gradient {
  text-align: center;
  left: 40px;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}



.cido-spaceship {
  overflow: hidden;
}

.cido-spaceship.animate-enter {
  -webkit-animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
          animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
  display: block;
  -webkit-transform: translate(0, 25px);
          transform: translate(0, 25px);
}

@-webkit-keyframes animate-enter-frames {
  from {
    opacity: 1;
    -webkit-transform: translate(0, 25px);
            transform: translate(0, 25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes animate-enter-frames {
  from {
    opacity: 1;
    -webkit-transform: translate(0, 25px);
            transform: translate(0, 25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

.info-wrap-one {
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.header-stat-box {
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.stat-box {
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 20px;
  text-align: center;
  bottom: 0;
}

.text-box {
  align-items: flex-end;
  color: #fff;
  grid-gap: 10px;
}

.text-box h3 {
  font-size: 1.3rem;
}

.p-box {
  font-size: 10px;
}

.cido-cont-two {
  display: flex;
  background-color: #09080d;
  justify-content: center;
}

.title-bottom {
  color: #fff;
  font-weight: 100;
  left: 0;
  padding: 5px;
  text-align: left;
}

.cido-youtube {
  padding: 20px;
}

.video {
  width: auto;
  height: auto;
}

.react-player {
  padding-right: 15%;
  position: relative;
}

.line {
  margin-bottom: 50px;
  height: 1px;

  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );

  -webkit-text-fill-color: unset;
}

.cido-interaction {
  display: inline-block;
  justify-content: center;
  grid-gap: 10px;
  align-items: center;
  padding: 20px;
}

.action {
  display: flex;
  grid-gap: 30px;
  color: #fff;
  font-size: 15px;
  font-weight: 100;
  padding-bottom: 20px;
}

.blank-line {
  height: 2px;
  width: 50%;
  background: rgba(52, 52, 68, 0.698);
}

.action-grid {
  display: grid;
  grid-gap: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: 100;
}

.part-line {
  height: 2px;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );

  -webkit-text-fill-color: unset;
}

.part-line-unselected {
  height: 2px;
  background: rgba(52, 52, 68, 0.698);
}

.user {
  display: flex;
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 5px;
}

.text-address {
  display: grid;
  grid-gap: 5px;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
}

.input-box {
  z-index: 3;
  position: relative;
  left: 48px;
  width: 220px;
  border: 2px solid #09080d;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  border-radius: 28px;
  background-color: rgba(52, 52, 68);
  text-align: center;
}

.input-value {
  background-color: transparent;
  color: #eeeeee;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #6969696c 1px;
  padding: 3px 5px;
}

input {
  width: 130px;
}

p {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.connect-text {
  font-size: 12px;
  font-weight: 100;
}

.address-text {
  font-size: 10px;
  font-weight: 200;
}

.caution {
  padding: 30px 50px 30px;
  color: rgb(148, 148, 148);
  font-size: 12px;
  font-weight: 300;
}

.img-busd {
  height: 30px;
  width: 30px;
  right: 0;
}

.balance-text {
  left: 0;
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}

.balance-amount {
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.balance {
  display: grid;
  flex-direction: column;

  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 5px;
}

.cont-function {
  display: flex;
  margin: 30px;
}

/* Reverse comportement of {@link GradientButton} component */
.cont-function .btn-basic {
  width: 200px;
  font-size: 17px !important;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.cont-function .btn-gradient {
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.cont-function .btn-gradient:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background: transparent;
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input-function {
  border: none;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  border-radius: 18px;
  background-color: rgba(52, 52, 68, 0.698);
  text-align: center;
}
.input-function:focus {
  border: 3px solid #555;
}

/* V2 Main Content */
.col-img-inr {
  width: 500px;
  border-radius: 500px 500px 24px 24px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 36px 36px 34px 36px;
  margin-right: 50px;
}

.col-img-otr {
  position: relative;
  bottom: 50px;
}

.col-content-inr {
  padding-left: 0px;
}

.image {
  position: relative;
  top: 0;
  border-radius: 16px;
}

.cido-title {
  font-size: 50px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.cido-info {
  justify-content: space-between;
  width: 500px;
  position: relative;
  padding-top: 100px;
  margin-top: 100px;
}

.bk {
  position: relative;
  right: 50px;
}

@media (max-width: 1200) {
  .row-custom {
    background-color: #09080d;
    padding: 0;
  }
}

.giveaway-container {
    display: flex;
    background-color: #09080d;
    justify-content: center;
  }

.give-title{
    font-size: 40px;
    line-height: 68px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
}

.image-giveaway {
    position: relative;
    top: 0;

    border-radius: 16px;
  }

.give-img {
    overflow: hidden;
    width: 100%;
    height: auto;
}

.give-img.animate-enter {
    -webkit-animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
            animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
    display: block;
    -webkit-transform: translate(0, 25px);
            transform: translate(0, 25px);
}

.give-info {
    justify-content: space-between;
    width: 400px;
    position: relative;
  }
.card-container {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 300px;
  padding: 20px;
  background: #343444;
  box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
  border-radius: 18px;
  margin-bottom: 40px;
  overflow: hidden;
  text-decoration: none;
}

.card-image {
  position: relative;
  margin-bottom: 21px;
  box-sizing: 20px;
  border-radius: 18px;
  overflow: hidden;
}

.card-image img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.card-title h5 {
  color: #ffffff;
  max-width: 75%;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  line-height: 26px;
}

.card-content {
  position: relative;
  margin-top: 5px;
  width: 90%;
  height: 17%;
  margin-left: auto;
  margin-right: auto;
}

.card-content h3 {
  font-size: 27px;
  color: #ffffff;
}

.card-description {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.author {
  display: flex;
  align-items: center;
}

.author .info {
  margin-top: 8px;
  font-size: 12px;
  color: #d2d2d2;
}

.author span {
  color: #ebebeb;
  font-size: 13px;
  line-height: 21px;
}

.author .info h6 {
  line-height: 22px;
  font-size: 15px;
  color: #ffffff;
}

.author .info h6 a {
  text-decoration: none;
  color: inherit;
}

.author .avatar {
  width: 44px;
  height: 44px;
  border-radius: 15px;
  margin-right: 12px;
  flex-shrink: 0;
  background-color: transparent;
}

.card-description .bid {
  margin-top: 8px;
  text-align: right;
  font-size: 12px;
  color: #d2d2d2;
}

.card-description .bid span {
  font-size: 13px;
  line-height: 21px;
}

.card-description .bid h5 {
  color: #ffffff;
  font-size: 18px;
}

.card-interaction {
  display: table-cell;
  vertical-align: middle;
  float: right;
  position: relative;
  z-index: 1;
  top: -10px;
  width: 100%;
}

.card-interaction .card-btn {
  position: absolute;
  background-color: black;
  width: 80px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
}

.card-interaction .card-btn span {
  font-size: 13px !important;
}

.card-interaction .card-btn.stat span {
  font-size: 15px !important;
}

.card-interaction .right {
  position: absolute;
  right: -10px;
}

.card-interaction .left {
  position: absolute;
  left: -10px;
}

.card-interaction .hype {
  color: #fec50a;
}

.card-interaction .stat {
  color: #ffffff;
}

.red {
  color: #ce4141;
}

.filter-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.filter-title,
.filter-content {
  padding: 1rem;
  color: #ffffff;
}

.filter-content {
}

input[type='checkbox'] {
  color: #ffffff;
  padding: 0;
  width: 18px;
  height: 18px;
  margin-right: 11px;
  cursor: pointer;
  display: inline;
  box-sizing: border-box;
  vertical-align: sub;
}

.filter-content input {
  line-height: inherit;
  margin: 0;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  margin-right: 0px;
}

.filter-content .btn-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 4px;
}

.filter-content .btn-checkbox {
  border: 1px solid #7a798a;
}

.filter-content label {
  line-height: 16px;
  font-size: 14px;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  letter-spacing: -0.5px;
}

.filter-content label input:checked ~ .btn-checkbox:after {
  display: block;
}

.filter-content .btn-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.widget-category .btn-checkbox:after {
  content: '\e921';
  font-family: 'nfts';
  font-size: 10px;
  left: 2px;
  top: 0px;
  color: #fff;
}

.filter-content label input:checked ~ .btn-checkbox {
  background-color: #943ff8;
  border: 1px solid #943ff8;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }
}

.side-box-dao {
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  width: 200px;
}

.dao-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.dao-box-left {
  justify-content: left;
  color: #ffffff;
  font-weight: 100;
}

.dao-box-right {
  text-align: right;
  font-weight: 350;
  font-size: 16px;
}

.market-moon {
  position: absolute;
  z-index: 0;
  height: 400px;
  top: 50%;
  right: 0;
  mix-blend-mode: screen;
  width: 300px;
  object-fit: cover;
  object-position: left bottom;
}

.market-section {
  display: block;
  margin: 0;
  padding: 0;
  padding: 80px 0;
  background-color: #09080d;
  margin-bottom: 0 !important;
}

.market-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 1440px;
  max-width: 100%;
}

.explore-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 45px;
  gap: 45px;
}

.side-bar {
  max-width: 600px;
  margin: 2rem auto;
  padding-right: 55px;
}

@media (max-width: 991px) {
  .side-bar {
    position: relative;
    padding-right: unset;
    margin: unset;
    width: 40%;
    display: inline-block;
    top: -33px;
  }

  .collection-info {
    width: 35%;
    display: inline-block;
  }

  .mobile-center {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .heading-btn-market {
    display: inline-table !important;
    width: 100%;
  }

  .heading-btn-market .button {
    display: flex !important;
    margin: 0 auto 20px !important;
  }
}

@media (max-width: 637px) {
  .side-bar {
    position: unset;
    margin: 2rem auto;
    padding-right: 55px;
    width: 40%;
    display: unset;
  }

  .mobile-center {
    text-align: unset;
  }

  .collection-info {
    width: 100%;
  }
}

.collection-info .title {
  padding: 1rem;
  font-size: 17px;
  letter-spacing: 0.3px;
  font-weight: medium;
}

.collection-info .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  font-size: 17px;
  font-weight: lighter;
}

.collection-info .author {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.collection-info .author img {
  border-radius: 100%;
  margin-right: 10px;
}

.collection-info .info {
  margin-top: 8px;
  font-size: 12px;
  color: #d2d2d2;
}

.header-section {
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  padding: 0;
  z-index: 1;
  background-color: #09080d;
}

.header-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.header-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.heading-content {
  font-size: 1.75rem;
  padding: 125px 0;
}

.heading {
  font-size: 45px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
}

.heading-content .heading {
  text-transform: uppercase;
  font-size: 50px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
}

.heading-content .story {
  font-size: 55px !important;
}

.heading-btn-market {
  margin-top: 50px;
  display: flex;
  justify-items: start;
  justify-content: left;
}

.heading-btn-market .button {
  width: 225px;
  font-size: 18px;
  font-weight: 200;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #5142fc;
  color: #ffffff;
  box-sizing: border-box;
  padding: 11px 35px;
  margin-inline: 10px;
  border-radius: 30px;
  background-color: transparent;
  transition: all 0.3s ease;
  position: relative;
}

.heading-btn-market .button a {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.1px;
}

.heading-btn-market .button:hover {
  background-color: #ffffff;
  color: #5142fc;
}

.gradient-text {
  font-size: 60px;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-content .heading .gradient-text {
  font-size: 50px !important;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1400px) {
  .gradient-text {
    font-size: 60px;
  }
}

.item-container {
  display: flex;
  justify-content: center;
  background-color: #09080d;
}

.item-row {
  margin-right: 0 !important;
}

.item-preview {
  display: flex;
  justify-content: center;
}

.item-preview .frame {
  position: relative;
  z-index: 1;
  width: 180%;
}

.item-preview .nft {
  position: absolute;
  left: 12.6%;
  top: 20vw;
  width: 23.9%;
}

.item-content {
  display: flex;
  justify-content: flex-start;
}

.item-content .item-description {
  padding: 12vw 0vw;
}

.item-description h2 {
  font-size: 35px;
}

.item-wrapper {
  width: 100%;
  text-align: left;
}

.user-box {
  display: inline-block;
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 250px;
  height: 54px;
}

.user-content {
  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 10px;
  margin-top: 6px;
}

.user-content img {
  border-radius: 100%;
  width: 37px;
  margin-right: 10px;
}

.user-desc {
  margin-top: 8px;
  font-size: 11px;
  color: #d2d2d2;
  text-decoration: none;
}

.user-desc h5 {
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}

.user-desc h6 {
  font-size: 11px;
  letter-spacing: 0.2px;
  font-weight: 300;
  line-height: 0.5;
}

.item-box {
  display: inline-block;
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 250px;
}

.item-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.item-left {
  justify-content: left;
  color: #ffffff;
  font-weight: 100;
}

.item-right {
  text-align: right;
  font-weight: 350;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
}

.item-story {
  color: #808080;
  font-size: 15px;
  font-weight: 100;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 510px;
}

.item-button {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  cursor: pointer;
}

/* Reverse comportement of {@link GradientButton} component */
.item-button .btn-basic {
  position: relative;
  right: 85px;
  height: 48px;
  width: 290px;
  font-size: 17px !important;
  letter-spacing: 0.5px;
  font-weight: 300;
  border-radius: 28px !important;
}

.item-button .btn-gradient {
  justify-content: space-around !important;
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.item-button .btn-gradient:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background: transparent;
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.item-button .item-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  z-index: 3;
  width: 300px;
  border: 2px solid #09080d;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  border-radius: 28px;
  background-color: rgba(52, 52, 68);
  text-align: center;
}

.item-button .input-value {
  background-color: transparent;
  color: #eeeeee;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #6969696c 1px;
  padding: 0px 50px;
}

input {
  width: 180px;
  font-size: 17px !important;
  font-weight: 100;
}

.item-input .icon {
  position: relative;
  right: 25px;
  color: #ffffff;
}

#main-slider-container {
  width: 100%;
  height: 553px;
  background-color: #09080d;
}

.slider-wrapper .card-container {
  margin-right: 50px;
}

#slider {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

#slider::-webkit-scrollbar {
  display: none;
}

#main-slider-container {
  position: relative;
  display: flex;
  align-items: center;
}

.slider-card-title,
.slider-card-description {
  margin: 5px 0px 5px 10px;
}

.slider-card-title {
  font-weight: 500;
  margin-top: 10px;
}

.slider-card-description {
  opacity: 0.5;
  font-size: 13px;
}

.slider-card-image {
  width: 100%;
  height: 220px;
  background-color: rgb(240 240 240 / 80%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.slider-icon.left,
.slider-icon.right {
  background: white;
  border-radius: 100%;
  position: absolute;
  opacity: 0.5;
  z-index: 2;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.slider-icon.left {
  left: 0;
}

.slider-icon.right {
  right: 0;
}

.slider-icon.left:hover,
.slider-icon.right:hover {
  opacity: 1;
}

.slider-card {
  width: 320px;
  height: 300px;
  background: white;
  border-radius: 10px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
}


.popup-dao-main-cont{

    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1024px;
    width: 100%;
    z-index: 100000;
}

.popup-dao-cont{
    position: relative;
    display: grid;
    height: 500px;
    width: 500px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #09080d;
}


.popup-dao-exit{
    position:absolute;
    padding: 10px;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: #fff
}


.dao-popup-header{
    font-weight: 200;
    top:0;
    color: #fff;
    font-size: 24px;
}

.popup-dao-pwr{
    font-weight: 100;
    color: #fff;

}

.dao-popup-input {
    z-index: 3;
    border: 1px solid #fff;
    border-radius: 12px;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    background-color: rgba(52, 52, 68);
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
  }

  .dao-popup-input-value {
    text-align: center;
    background-color: transparent;
    color: #eeeeee;
    outline: none;
    outline-style: none;
    outline-offset: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid #6969696c 1px;
    padding: 3px 5px;
  }
  

 .popup-dao-desc{
     display: grid;
     padding: 20px
 }

 .dao-popup-t-desc{
     font-size: 18px;
     font-weight: 100;
     text-align: left;
     color: #fff
 }

 .dao-popup-line {
    margin-bottom: 20px;
    height: 1px;
  
    background-image: linear-gradient(
      to right,
      #3d7ffb 0%,
      #af75f4 61%,
      #eb70f1 100%
    );
  
    -webkit-text-fill-color: unset;
  }
  
  .popup-dao-description-p{
      font-size: 14px;
      color: #fff;
      font-weight: 100;
  }
.wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 25px;
}

.stats-container{
    margin-left: 10%;
    margin-right: 5%;
}

.slides-container{
    margin-left: 10%;
    margin-right: 5%;
    margin-top: 5%;
}

.gradient-line {
    height: 5px;
    background: linear-gradient(60deg, #3d7ffb, #eb70f1);
    margin-bottom: 2%;
}

li {
    font-size: 25px;
    color: #ffffff;
    display: flex;
    transition: .5s;
    cursor: pointer;
}

li p:nth-child(1) {
    width: 5%;
}

li p:nth-child(2) {
    width: 50%;
}

li p:nth-child(3) {
    width: 10%;
}

li p:nth-child(4) {
    width: 10%;
}

li img {
    display: none;
}

li:hover img {
    display: block;
    height: 100%;
    border-radius: 10%;
    position: absolute;
    margin-left: 75vw;
    top: 0;
}

.dropdown-item{
    position: relative;
    width: auto;
    height: auto;
    background-color: #09080d !important;
    overflow:hidden;
}

.collection-3 {
  display: flex;
  flex-direction: column;
  height: 614px;
  justify-content: flex-end;
  width: 540px;
}

.collection-frame {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 1 1;
  margin-bottom: 21.7px;
  margin-left: 0.4px;
  min-width: 100%;
  padding: 10.6px 10.6px;
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 512px;
  width: 167px;
}

.punk {
  height: 251px;
  object-fit: cover;
  width: 167px;
}

.pirate_lenny {
  height: 251px;
  margin-top: 10px;
  object-fit: cover;
  width: 167px;
}

.medieval {
  height: 512px;
  margin-left: 10px;
  object-fit: cover;
  width: 341px;
}

.description {
  align-items: flex-start;
  display: flex;
  flex: 1 1;
  height: 58px;
  margin-right: 0.2px;
  max-height: 58px;
  min-width: 100%;
}

.flex-col-1 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 56px;
  width: 229px;
  font-size: 20px;
}

.collection-2 {
  letter-spacing: 0.63px;
  line-height: 21px;
  min-height: 27px;
  white-space: nowrap;
}

.flex-row {
  align-items: flex-end;
  display: flex;
  margin-top: 4px;
  min-width: 229px;
}

.address {
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0.5px;
  min-height: 21px;
  min-width: 139px;
  white-space: nowrap;
}

.artist {
  display: flex;
  height: 25px;
  justify-content: space-between;
  margin-left: 3px;
  width: 87px;
}

.avt-8 {
  border-radius: 12.5px;
  object-fit: cover;
  width: 25px;
  margin-left: 5px;
}

.kalai {
  height: 21px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: -2px;
  margin-top: 3.5px;
  white-space: nowrap;
  width: 55px;
}

.like {
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin-left: 262px;
  margin-top: 5px;
  width: 51px;
}

.icon-ionic-ios-heart-empty {
  margin-bottom: 2.3px;
  margin-left: 0;
  margin-top: 2.3px;
  object-fit: cover;
  width: 15.99px;
}

.number {
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: -4px;
  margin-right: -2px;
  margin-top: 2px;
  white-space: nowrap;
  width: 29px;
}


#main-slider-container {
  width: 100%;
  height: 753px;
  background-color: #09080d;
}


.slider-wrapper .card-container {
  margin-right: 50px;
}

.slider-collection-wrapper{
  display: flex;
  grid-gap: 20px;
}

#slider {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

#slider::-webkit-scrollbar {
  display: none;
}

#main-slider-container {
  position: relative;
  display: flex;
  align-items: center;
}

.slider-card-title,
.slider-card-description {
  margin: 5px 0px 5px 10px;
}

.slider-card-title {
  font-weight: 500;
  margin-top: 10px;
}

.slider-card-description {
  opacity: 0.5;
  font-size: 13px;
}

.slider-card-image {
  width: 100%;
  height: 320px;
  background-color: rgb(240 240 240 / 80%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.collection-slider-icon.left,
.collection-slider-icon.right {
  background: white;
  border-radius: 100%;
  position: absolute;
  opacity: 0.5;
  z-index: 2;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.collection-slider-icon.left {
  left: 0;
  margin-bottom: 12%;
}

.collection-slider-icon.right {
  right: 0;
  margin-bottom: 12%;
}

.collection-slider-icon.left:hover,
.collection-slider-icon.right:hover {
  opacity: 1;
}

.slider-card {
  width: 320px;
  height: 300px;
  background: white;
  border-radius: 10px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
}



.dao-container-one {
    display: flex;
    background-color: #09080d;
    justify-content: center;
}

.row-custom-dao {
    padding: 96px 100px 128px 100px;
    background-color: #09080d;
    justify-content: center;
    align-items: center;
    margin-right: 0 !important;
    margin-top: 0 !important;
}


.col-img-otr-dao {
    position: relative;
    bottom: 50px;
}


.dao-title {
    font-size: 50px;
    line-height: 68px;
    font-weight: 300;
    color: #ffffff;
    text-transform: uppercase;
  }

  .image-dao {
    position: relative;
    top: 0;
    border-radius: 16px;
    padding: 40px;
}

  
  .dao-spaceship {
    overflow: hidden;
    height: 500px;


  }

.dao-spaceship.animate-enter {
    -webkit-animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
            animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
    display: block;
    -webkit-transform: translate(0, 25px);
            transform: translate(0, 25px);
}

.dao-container-two {
  display: block;
  margin: 0;
  padding: 0;
  padding: 80px 0;
  background-color: #09080d;
  margin-bottom: 0 !important;
}



.dao-collection{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 1440px;
    max-width: 100%;
}

.side-box-dao {
    background-color: rgba(52, 52, 68, 0.698);
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    width: 200px;
  }

.dao-box-wrapper{
    display:flex;
    justify-content:space-between;
    font-size: 14px;

}

.dao-box-left{
    justify-content:left;
    color: #ffffff;
    font-weight: 100;

}

.dao-box-right{
    text-align: right;
    color: #ffffff;

}

.stats-dropdown{
  background-color: #09080d;
  color: #ffffff;
}
