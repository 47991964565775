.side-box-dao {
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 12px;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  width: 200px;
}

.dao-box-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.dao-box-left {
  justify-content: left;
  color: #ffffff;
  font-weight: 100;
}

.dao-box-right {
  text-align: right;
  font-weight: 350;
  font-size: 16px;
}
