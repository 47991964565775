@import url('https://fonts.googleapis.com/css?family=Poppins:400,600|Montserrat:700,500');

.header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 900;
  background: #09080d;
}

.navigation {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 120;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
}

@media (max-width: 643px) {
  #nav {
    display: none;
  }
}

.title {
  color: #ffffff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
}

.logo {
  font-size: 1.9rem !important;
}

.title:hover {
  color: #ffffff;
}

.menu {
  margin-right: 30px;
  margin-left: auto;
  display: flex;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.menu ul {
  align-items: flex-end;
  display: flex;
  margin: 0 auto;

  color: #ffffff;
  cursor: pointer;
}

.menu ul li {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-weight: 100;
}

.menu ul li a:hover {
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu ul li a {
  color: #ffffff;
}

.menu ul li a:visited {
  color: #ffffff;
  font-size: 40px;
}

.menu ul li a:link {
  display: flex;
  color: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  font-size: 18px;

  justify-content: center;
  text-decoration: none;
}

.user-profile {
  display: flex;
  align-items: center;
}

.profile-menu {
  background: #181820;
  color: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 80px;
  right: 105px;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.profile-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.profile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-menu li a {
  text-decoration: none;
  font-size: 17px;
  color: #ffffff;
  padding: 15px 0;
  display: block;
}

.profile-dropdown {
  padding: 15px 20px;
}

.profile-dropdown .profile-info {
  margin-top: 10px;
  font-size: 13px;
}

.profile-dropdown .profile-info p {
  line-height: 10px;
  font-weight: 100;
}

.profile-dropdown .profile-info span {
  font-size: 17px !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #af75f4;
}

.profile-dropdown .profile-info .profile-wallet {
  font-size: 17px !important;
  opacity: 80%;
  font-weight: 400;
  text-transform: initial;
  color: #ffffff;
}

hr.line {
  height: 1px !important;
  margin-bottom: 10px;
}
