.market-moon {
  position: absolute;
  z-index: 0;
  height: 400px;
  top: 50%;
  right: 0;
  mix-blend-mode: screen;
  width: 300px;
  object-fit: cover;
  object-position: left bottom;
}

.market-section {
  display: block;
  margin: 0;
  padding: 0;
  padding: 80px 0;
  background-color: #09080d;
  margin-bottom: 0 !important;
}

.market-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 1440px;
  max-width: 100%;
}

.explore-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 45px;
}

.side-bar {
  max-width: 600px;
  margin: 2rem auto;
  padding-right: 55px;
}

@media (max-width: 991px) {
  .side-bar {
    position: relative;
    padding-right: unset;
    margin: unset;
    width: 40%;
    display: inline-block;
    top: -33px;
  }

  .collection-info {
    width: 35%;
    display: inline-block;
  }

  .mobile-center {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .heading-btn-market {
    display: inline-table !important;
    width: 100%;
  }

  .heading-btn-market .button {
    display: flex !important;
    margin: 0 auto 20px !important;
  }
}

@media (max-width: 637px) {
  .side-bar {
    position: unset;
    margin: 2rem auto;
    padding-right: 55px;
    width: 40%;
    display: unset;
  }

  .mobile-center {
    text-align: unset;
  }

  .collection-info {
    width: 100%;
  }
}

.collection-info .title {
  padding: 1rem;
  font-size: 17px;
  letter-spacing: 0.3px;
  font-weight: medium;
}

.collection-info .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  font-size: 17px;
  font-weight: lighter;
}

.collection-info .author {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.collection-info .author img {
  border-radius: 100%;
  margin-right: 10px;
}

.collection-info .info {
  margin-top: 8px;
  font-size: 12px;
  color: #d2d2d2;
}

.header-section {
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  padding: 0;
  z-index: 1;
  background-color: #09080d;
}

.header-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
}

.header-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.heading-content {
  font-size: 1.75rem;
  padding: 125px 0;
}

.heading {
  font-size: 45px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
}

.heading-content .heading {
  text-transform: uppercase;
  font-size: 50px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
}

.heading-content .story {
  font-size: 55px !important;
}

.heading-btn-market {
  margin-top: 50px;
  display: flex;
  justify-items: start;
  justify-content: left;
}

.heading-btn-market .button {
  width: 225px;
  font-size: 18px;
  font-weight: 200;
  text-decoration: none;
  display: inline-block;
  border: 1px solid #5142fc;
  color: #ffffff;
  box-sizing: border-box;
  padding: 11px 35px;
  margin-inline: 10px;
  border-radius: 30px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
}

.heading-btn-market .button a {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.1px;
}

.heading-btn-market .button:hover {
  background-color: #ffffff;
  color: #5142fc;
}

.gradient-text {
  font-size: 60px;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-content .heading .gradient-text {
  font-size: 50px !important;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1400px) {
  .gradient-text {
    font-size: 60px;
  }
}
