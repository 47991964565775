.wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 25px;
}

.stats-container{
    margin-left: 10%;
    margin-right: 5%;
}

.slides-container{
    margin-left: 10%;
    margin-right: 5%;
    margin-top: 5%;
}

.gradient-line {
    height: 5px;
    background: linear-gradient(60deg, #3d7ffb, #eb70f1);
    margin-bottom: 2%;
}

li {
    font-size: 25px;
    color: #ffffff;
    display: flex;
    transition: .5s;
    cursor: pointer;
}

li p:nth-child(1) {
    width: 5%;
}

li p:nth-child(2) {
    width: 50%;
}

li p:nth-child(3) {
    width: 10%;
}

li p:nth-child(4) {
    width: 10%;
}

li img {
    display: none;
}

li:hover img {
    display: block;
    height: 100%;
    border-radius: 10%;
    position: absolute;
    margin-left: 75vw;
    top: 0;
}

.dropdown-item{
    position: relative;
    width: auto;
    height: auto;
    background-color: #09080d !important;
    overflow:hidden;
}
