#main-slider-container {
  width: 100%;
  height: 753px;
  background-color: #09080d;
}


.slider-wrapper .card-container {
  margin-right: 50px;
}

.slider-collection-wrapper{
  display: flex;
  grid-gap: 20px;
}

#slider {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

#slider::-webkit-scrollbar {
  display: none;
}

#main-slider-container {
  position: relative;
  display: flex;
  align-items: center;
}

.slider-card-title,
.slider-card-description {
  margin: 5px 0px 5px 10px;
}

.slider-card-title {
  font-weight: 500;
  margin-top: 10px;
}

.slider-card-description {
  opacity: 0.5;
  font-size: 13px;
}

.slider-card-image {
  width: 100%;
  height: 320px;
  background-color: rgb(240 240 240 / 80%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.collection-slider-icon.left,
.collection-slider-icon.right {
  background: white;
  border-radius: 100%;
  position: absolute;
  opacity: 0.5;
  z-index: 2;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.collection-slider-icon.left {
  left: 0;
  margin-bottom: 12%;
}

.collection-slider-icon.right {
  right: 0;
  margin-bottom: 12%;
}

.collection-slider-icon.left:hover,
.collection-slider-icon.right:hover {
  opacity: 1;
}

.slider-card {
  width: 320px;
  height: 300px;
  background: white;
  border-radius: 10px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
  cursor: pointer;
}
