.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.popup-inner {
    position: relative;
    text-align: center;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    border-radius: 20px;

}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}

.popup-inner .img-logo {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 4rem;
    width: 4rem;

}