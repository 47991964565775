.footer-container {
  position: relative;
  cursor: pointer;
  background-color: #000000;
}

@media (max-width: 643px) {
  .footer-links-wrap,
  .socialmedia {
    flex-direction: column;
  }

  .footer-links-items {
    margin: 0;
    padding: 10px;
    width: 100%;
  }

  .copyright-wrap {
    margin-left: unset !important;
  }

  .socialmedia {
    padding-left: unset !important;
    padding-right: unset !important;
  }
}

.footer-wrap {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-links-cont {
  display: flex;
  width: 100%;
}

.footer-links-wrap {
  display: flex;
  max-width: 1100px;
  margin: auto;
}

.footer-links-items {
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  width: auto;
  box-sizing: border-box;
  color: #ffffff;
}

.footer-links-logo {
  font-size: 35px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: #ffffff;
}

.footer-links-title {
  font-size: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
  color: #ffffff;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  padding-bottom: 0.5rem;
  font-size: 14px;
}

.footer-link:hover {
  background: -moz-linear-gradient(60deg, #3d7ffb, #eb70f1);
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  background: linear-gradient(60deg, #3d7ffb, #eb70f1);

  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  transition: 0.3s ease-out;
}

.socialmedia {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
  display: flex;
  width: 100%;
}

.socialmedia-wrap {
  margin-right: auto;
  left: 10%;
}

.copyright-wrap {
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: auto;
}

.social-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  padding-right: 10px;
}

.website-rights {
  color: #ffffff;
  font-size: 12px;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  width: 100%;

  @media screen and (max-width: 820px) {
    align-items: center;
  }
}

.footer-description {
  display: flex;
  max-width: 500px;
  font-weight: 100;
  font-size: 14px;
  padding-bottom: 1rem;
}

.footer-text-wrap {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  color: #ffffff;
}

.footer-logo-cont {
  background: transparent;
}

.footer-logo-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  max-height: 3rem;
  max-width: 3rem;

  --o-object-fit: cover;
  object-fit: cover;
  background: transparent;
}
