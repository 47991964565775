.card-container {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 300px;
  padding: 20px;
  background: #343444;
  box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
  border-radius: 18px;
  margin-bottom: 40px;
  overflow: hidden;
  text-decoration: none;
}

.card-image {
  position: relative;
  margin-bottom: 21px;
  box-sizing: 20px;
  border-radius: 18px;
  overflow: hidden;
}

.card-image img {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.card-title h5 {
  color: #ffffff;
  max-width: 75%;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  line-height: 26px;
}

.card-content {
  position: relative;
  margin-top: 5px;
  width: 90%;
  height: 17%;
  margin-left: auto;
  margin-right: auto;
}

.card-content h3 {
  font-size: 27px;
  color: #ffffff;
}

.card-description {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.author {
  display: flex;
  align-items: center;
}

.author .info {
  margin-top: 8px;
  font-size: 12px;
  color: #d2d2d2;
}

.author span {
  color: #ebebeb;
  font-size: 13px;
  line-height: 21px;
}

.author .info h6 {
  line-height: 22px;
  font-size: 15px;
  color: #ffffff;
}

.author .info h6 a {
  text-decoration: none;
  color: inherit;
}

.author .avatar {
  width: 44px;
  height: 44px;
  border-radius: 15px;
  margin-right: 12px;
  flex-shrink: 0;
  background-color: transparent;
}

.card-description .bid {
  margin-top: 8px;
  text-align: right;
  font-size: 12px;
  color: #d2d2d2;
}

.card-description .bid span {
  font-size: 13px;
  line-height: 21px;
}

.card-description .bid h5 {
  color: #ffffff;
  font-size: 18px;
}

.card-interaction {
  display: table-cell;
  vertical-align: middle;
  float: right;
  position: relative;
  z-index: 1;
  top: -10px;
  width: 100%;
}

.card-interaction .card-btn {
  position: absolute;
  background-color: black;
  width: 80px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
}

.card-interaction .card-btn span {
  font-size: 13px !important;
}

.card-interaction .card-btn.stat span {
  font-size: 15px !important;
}

.card-interaction .right {
  position: absolute;
  right: -10px;
}

.card-interaction .left {
  position: absolute;
  left: -10px;
}

.card-interaction .hype {
  color: #fec50a;
}

.card-interaction .stat {
  color: #ffffff;
}

.red {
  color: #ce4141;
}
