.cido-container {
  display: flex;
  background-color: #09080d;
  justify-content: center;
}

.row-custom {
  padding: 96px 100px 128px 100px;
  background-color: #09080d;
  justify-content: center;
  align-items: center;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.cido-cont-one {
  height: 50vh;
  width: 100%;
  background-color: #09080d;
  justify-content: center;
}

.cido-wrap-one {
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.title-white {
  text-align: center;
  color: #fff;
}

.title-gradient {
  text-align: center;
  left: 40px;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}



.cido-spaceship {
  overflow: hidden;
}

.cido-spaceship.animate-enter {
  animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
  display: block;
  transform: translate(0, 25px);
}

@keyframes animate-enter-frames {
  from {
    opacity: 1;
    transform: translate(0, 25px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.info-wrap-one {
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.header-stat-box {
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.stat-box {
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 20px;
  text-align: center;
  bottom: 0;
}

.text-box {
  align-items: flex-end;
  color: #fff;
  grid-gap: 10px;
}

.text-box h3 {
  font-size: 1.3rem;
}

.p-box {
  font-size: 10px;
}

.cido-cont-two {
  display: flex;
  background-color: #09080d;
  justify-content: center;
}

.title-bottom {
  color: #fff;
  font-weight: 100;
  left: 0;
  padding: 5px;
  text-align: left;
}

.cido-youtube {
  padding: 20px;
}

.video {
  width: auto;
  height: auto;
}

.react-player {
  padding-right: 15%;
  position: relative;
}

.line {
  margin-bottom: 50px;
  height: 1px;

  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );

  -webkit-text-fill-color: unset;
}

.cido-interaction {
  display: inline-block;
  justify-content: center;
  grid-gap: 10px;
  align-items: center;
  padding: 20px;
}

.action {
  display: flex;
  grid-gap: 30px;
  color: #fff;
  font-size: 15px;
  font-weight: 100;
  padding-bottom: 20px;
}

.blank-line {
  height: 2px;
  width: 50%;
  background: rgba(52, 52, 68, 0.698);
}

.action-grid {
  display: grid;
  grid-gap: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: 100;
}

.part-line {
  height: 2px;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );

  -webkit-text-fill-color: unset;
}

.part-line-unselected {
  height: 2px;
  background: rgba(52, 52, 68, 0.698);
}

.user {
  display: flex;
  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 5px;
}

.text-address {
  display: grid;
  grid-gap: 5px;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
}

.input-box {
  z-index: 3;
  position: relative;
  left: 48px;
  width: 220px;
  border: 2px solid #09080d;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  border-radius: 28px;
  background-color: rgba(52, 52, 68);
  text-align: center;
}

.input-value {
  background-color: transparent;
  color: #eeeeee;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #6969696c 1px;
  padding: 3px 5px;
}

input {
  width: 130px;
}

p {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

.connect-text {
  font-size: 12px;
  font-weight: 100;
}

.address-text {
  font-size: 10px;
  font-weight: 200;
}

.caution {
  padding: 30px 50px 30px;
  color: rgb(148, 148, 148);
  font-size: 12px;
  font-weight: 300;
}

.img-busd {
  height: 30px;
  width: 30px;
  right: 0;
}

.balance-text {
  left: 0;
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}

.balance-amount {
  display: flex;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.balance {
  display: grid;
  flex-direction: column;

  background-color: rgba(52, 52, 68, 0.698);
  border-radius: 18px;
  padding: 5px;
}

.cont-function {
  display: flex;
  margin: 30px;
}

/* Reverse comportement of {@link GradientButton} component */
.cont-function .btn-basic {
  width: 200px;
  font-size: 17px !important;
  letter-spacing: 0.5px;
  font-weight: 300;
}

.cont-function .btn-gradient {
  text-decoration: none;
  color: white;
  border: 0;
  background: transparent;
  background-position: center;
  background-image: linear-gradient(
    to right,
    #3d7ffb 0%,
    #af75f4 61%,
    #eb70f1 100%
  );
  -webkit-text-fill-color: unset;
}

.cont-function .btn-gradient:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  background: transparent;
  color: white;
  background: -webkit-linear-gradient(60deg, #3d7ffb, #eb70f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input-function {
  border: none;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  border-radius: 18px;
  background-color: rgba(52, 52, 68, 0.698);
  text-align: center;
}
.input-function:focus {
  border: 3px solid #555;
}

/* V2 Main Content */
.col-img-inr {
  width: 500px;
  border-radius: 500px 500px 24px 24px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 36px 36px 34px 36px;
  margin-right: 50px;
}

.col-img-otr {
  position: relative;
  bottom: 50px;
}

.col-content-inr {
  padding-left: 0px;
}

.image {
  position: relative;
  top: 0;
  border-radius: 16px;
}

.cido-title {
  font-size: 50px;
  line-height: 68px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.cido-info {
  justify-content: space-between;
  width: 500px;
  position: relative;
  padding-top: 100px;
  margin-top: 100px;
}

.bk {
  position: relative;
  right: 50px;
}

@media (max-width: 1200) {
  .row-custom {
    background-color: #09080d;
    padding: 0;
  }
}
