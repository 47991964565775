.popup-dao-main-cont{

    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1024px;
    width: 100%;
    z-index: 100000;
}

.popup-dao-cont{
    position: relative;
    display: grid;
    height: 500px;
    width: 500px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #09080d;
}


.popup-dao-exit{
    position:absolute;
    padding: 10px;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: #fff
}


.dao-popup-header{
    font-weight: 200;
    top:0;
    color: #fff;
    font-size: 24px;
}

.popup-dao-pwr{
    font-weight: 100;
    color: #fff;

}

.dao-popup-input {
    z-index: 3;
    border: 1px solid #fff;
    border-radius: 12px;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    background-color: rgba(52, 52, 68);
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
  }

  .dao-popup-input-value {
    text-align: center;
    background-color: transparent;
    color: #eeeeee;
    outline: none;
    outline-style: none;
    outline-offset: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid #6969696c 1px;
    padding: 3px 5px;
  }
  

 .popup-dao-desc{
     display: grid;
     padding: 20px
 }

 .dao-popup-t-desc{
     font-size: 18px;
     font-weight: 100;
     text-align: left;
     color: #fff
 }

 .dao-popup-line {
    margin-bottom: 20px;
    height: 1px;
  
    background-image: linear-gradient(
      to right,
      #3d7ffb 0%,
      #af75f4 61%,
      #eb70f1 100%
    );
  
    -webkit-text-fill-color: unset;
  }
  
  .popup-dao-description-p{
      font-size: 14px;
      color: #fff;
      font-weight: 100;
  }