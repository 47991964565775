.giveaway-container {
    display: flex;
    background-color: #09080d;
    justify-content: center;
  }

.give-title{
    font-size: 40px;
    line-height: 68px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
}

.image-giveaway {
    position: relative;
    top: 0;

    border-radius: 16px;
  }

.give-img {
    overflow: hidden;
    width: 100%;
    height: auto;
}

.give-img.animate-enter {
    animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
    display: block;
    transform: translate(0, 25px);
}

.give-info {
    justify-content: space-between;
    width: 400px;
    position: relative;
  }