

.dao-container-one {
    display: flex;
    background-color: #09080d;
    justify-content: center;
}

.row-custom-dao {
    padding: 96px 100px 128px 100px;
    background-color: #09080d;
    justify-content: center;
    align-items: center;
    margin-right: 0 !important;
    margin-top: 0 !important;
}


.col-img-otr-dao {
    position: relative;
    bottom: 50px;
}


.dao-title {
    font-size: 50px;
    line-height: 68px;
    font-weight: 300;
    color: #ffffff;
    text-transform: uppercase;
  }

  .image-dao {
    position: relative;
    top: 0;
    border-radius: 16px;
    padding: 40px;
}

  
  .dao-spaceship {
    overflow: hidden;
    height: 500px;


  }

.dao-spaceship.animate-enter {
    animation: animate-enter-frames 3s ease-in-out 0s 1 normal forwards;
    display: block;
    transform: translate(0, 25px);
}

.dao-container-two {
  display: block;
  margin: 0;
  padding: 0;
  padding: 80px 0;
  background-color: #09080d;
  margin-bottom: 0 !important;
}



.dao-collection{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 1440px;
    max-width: 100%;
}

.side-box-dao {
    background-color: rgba(52, 52, 68, 0.698);
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    width: 200px;
  }

.dao-box-wrapper{
    display:flex;
    justify-content:space-between;
    font-size: 14px;

}

.dao-box-left{
    justify-content:left;
    color: #ffffff;
    font-weight: 100;

}

.dao-box-right{
    text-align: right;
    color: #ffffff;

}

.stats-dropdown{
  background-color: #09080d;
  color: #ffffff;
}