* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Sofia Pro', sans-serif;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
}
