.filter-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.filter-title,
.filter-content {
  padding: 1rem;
  color: #ffffff;
}

.filter-content {
}

input[type='checkbox'] {
  color: #ffffff;
  padding: 0;
  width: 18px;
  height: 18px;
  margin-right: 11px;
  cursor: pointer;
  display: inline;
  box-sizing: border-box;
  vertical-align: sub;
}

.filter-content input {
  line-height: inherit;
  margin: 0;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  margin-right: 0px;
}

.filter-content .btn-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border-radius: 4px;
}

.filter-content .btn-checkbox {
  border: 1px solid #7a798a;
}

.filter-content label {
  line-height: 16px;
  font-size: 14px;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  letter-spacing: -0.5px;
}

.filter-content label input:checked ~ .btn-checkbox:after {
  display: block;
}

.filter-content .btn-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.widget-category .btn-checkbox:after {
  content: '\e921';
  font-family: 'nfts';
  font-size: 10px;
  left: 2px;
  top: 0px;
  color: #fff;
}

.filter-content label input:checked ~ .btn-checkbox {
  background-color: #943ff8;
  border: 1px solid #943ff8;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }
}
