.collection-3 {
  display: flex;
  flex-direction: column;
  height: 614px;
  justify-content: flex-end;
  width: 540px;
}

.collection-frame {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 1;
  margin-bottom: 21.7px;
  margin-left: 0.4px;
  min-width: 100%;
  padding: 10.6px 10.6px;
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 512px;
  width: 167px;
}

.punk {
  height: 251px;
  object-fit: cover;
  width: 167px;
}

.pirate_lenny {
  height: 251px;
  margin-top: 10px;
  object-fit: cover;
  width: 167px;
}

.medieval {
  height: 512px;
  margin-left: 10px;
  object-fit: cover;
  width: 341px;
}

.description {
  align-items: flex-start;
  display: flex;
  flex: 1;
  height: 58px;
  margin-right: 0.2px;
  max-height: 58px;
  min-width: 100%;
}

.flex-col-1 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 56px;
  width: 229px;
  font-size: 20px;
}

.collection-2 {
  letter-spacing: 0.63px;
  line-height: 21px;
  min-height: 27px;
  white-space: nowrap;
}

.flex-row {
  align-items: flex-end;
  display: flex;
  margin-top: 4px;
  min-width: 229px;
}

.address {
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0.5px;
  min-height: 21px;
  min-width: 139px;
  white-space: nowrap;
}

.artist {
  display: flex;
  height: 25px;
  justify-content: space-between;
  margin-left: 3px;
  width: 87px;
}

.avt-8 {
  border-radius: 12.5px;
  object-fit: cover;
  width: 25px;
  margin-left: 5px;
}

.kalai {
  height: 21px;
  letter-spacing: 0;
  line-height: 16px;
  margin-right: -2px;
  margin-top: 3.5px;
  white-space: nowrap;
  width: 55px;
}

.like {
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin-left: 262px;
  margin-top: 5px;
  width: 51px;
}

.icon-ionic-ios-heart-empty {
  margin-bottom: 2.3px;
  margin-left: 0;
  margin-top: 2.3px;
  object-fit: cover;
  width: 15.99px;
}

.number {
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: -4px;
  margin-right: -2px;
  margin-top: 2px;
  white-space: nowrap;
  width: 29px;
}

